<template>
    <v-app>
        <v-container>
            <v-card>
                <v-card-title class="d-flex align-center">
                    <v-icon class="mr-2" size="40">mdi-earth</v-icon>
                    <span class="font-weight-bold mr-2 text-uppercase">Procesos de Compra</span>
                </v-card-title>
                <v-divider />
    
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12" md="4">
                            <v-text-field 
                                v-model="filtros.nombre" 
                                label="Código / Nombre de proceso" 
                                outlined 
                                clearable
                                hide-details 
                            />
                        </v-col>
    
                        <v-col cols="12" sm="12" md="4">
                            <ProgresiveLoadAutocomplete v-model="filtros.id_institucion" :pageable="instituciones"
                                :loading="instituciones.isLoading" class="flex-grow-1 flex-shrink-1"
                                style="min-width: 200px;" label="Institución" placeholder="Seleccione una institución"
                                itemText="nombre" itemValue="id" hide-details @search="buscarInstitucion"
                                @load-more="cargarMasInstituciones" clearable />
                        </v-col>
    
                        <v-col cols="12" sm="12" md="4">
                            <div class="d-flex align-center justify-space-around flex-column flex-sm-row"
                                style="gap: 16px;">
                                <v-btn dark color="primary" class="flex-shrink-1 flex-grow-1" style="width: 100%;"
                                    @click="buscarProcesos" x-large>
                                    <v-icon> mdi-magnify </v-icon>
                                    Buscar
                                </v-btn>
                                <v-btn outlined color="secondary" class="flex-shrink-1 flex-grow-1" style="width: 100%;"
                                    @click="limpiarFiltros()" x-large>
                                    Limpiar
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
    
                    <DataTableComponent 
                        class="product-table mt-4" 
                        :headers="headers" 
                        :items="procesos.data"
                        :loading="procesos.isLoading"
                        :total_registros="total" 
                        :show_loading="procesos.isLoading"
                        v-models:pagina="paginacion.page" 
                        v-models:select="paginacion.per_page"
                        @paginar="manejarPaginacionRegistros"
                        dense
                        no-gutters
                    >
                        <template v-slot:[`item.fecha_adjudicacion`]="{ item }">
                            {{ item.proceso_compra.fecha_adjudicacion ? formatDate(item.proceso_compra.fecha_adjudicacion) : '-' }}
                        </template>
                        <template v-slot:[`item.monto`]="{ item }">
                            {{ toMoneyFormat(item.monto) }}
                        </template>
                        <template v-slot:[`item.beneficiario_final`]="{ item }">
                            <div class="py-2">
                                <span class="text-body-2 font-weight-medium">{{ obtenerNombreBeneficiario(item) }}</span>
                                <v-chip label small class="mt-1 py-4">
                                    <v-icon class="mr-1" size="18px !important">mdi-earth</v-icon>
                                    {{ obtenerNacionalidadBeneficiario(item) }}
                                </v-chip>
                            </div>
                        </template>
                    </DataTableComponent>
                </v-card-text>
            </v-card>
        </v-container>
        <v-spacer class="my-4"></v-spacer>
        <v-footer fixed class=" login-footer primary py-3 d-flex flex-wrap justify-center align-center" style="z-index: 100; gap: 32px; border: 20px, solid, blue">
            <v-img class="flex-shrink-1" src="@/assets/img/goes_hrz_blanco.png" max-width="300px" />
            <div class="login-footer-institucion d-flex align-center" style="gap: 16px">
                <v-img contain class="d-flex logo__img" src="@/assets/img/logo-dinac-simple.png" max-width="125" />
                <a href="https://x.com/dinacsv" target="_blank">
                    <v-icon color="#FFF !important" x-large>mdi-twitter</v-icon>
                </a>
                <a href="https://dinac.gob.sv" target="_blank">
                    <v-icon color="#FFF !important" x-large>mdi-web</v-icon>
                </a>
            </div>
        </v-footer>
    </v-app>
</template>
<script>

import { ProgresiveLoadAutocomplete } from '@/components/utils';
import DataTableComponent from "@/components/DataTableComponent.vue";
import moment from "moment";
import {
    createPageable,
    createLoadable,
    togglePageable,
    setPageableResponse,
    isResponseSuccesful,
} from '@/utils/loadable.js';
import { obtenerNombreCompletoPersona, toMoneyFormat } from '@/utils/data';


export default {
    name: "ProcesoPublicoView",
    components: { DataTableComponent, ProgresiveLoadAutocomplete },
    data: () => ({
        headers: [
            { text: 'Codigo proceso', value: 'proceso_compra.codigo_proceso', align: 'center', sortable: false },
            { text: 'Nombre proceso', value: 'proceso_compra.nombre_proceso', align: 'center', sortable: false },
            { text: 'Institución', value: 'institucion.nombre', align: 'center', sortable: false },
            { text: 'Fecha adjudicación', value: 'proceso_compra.fecha_adjudicacion', align: 'center', sortable: false },
            { text: 'Empresa adjudicada', value: 'proveedor.nombre_comercial', align: 'center', sortable: false },
            { text: 'Beneficiario final', value: 'beneficiario_final', align: 'center', sortable: false },
            { text: 'Monto adjudicado', value: 'monto', align: 'center', sortable: false },
        ],
        procesos: createPageable([], 10),
        filtros: {
            id_institucion: null,
            nombre: null,
        },
        busquedas: {
            institucion: null,
        },
        // Filtros
        instituciones: createPageable([], 30),
        paginacionInstituciones: {
            pagina: 1,
            registrosPorPagina: 30,
        },
        paginacion: {
            page: 1,
            per_page: 10,
        },
    }),
    computed: {
        total() {
            return this.procesos.pagination.total_rows;
        },
    },
    methods: {
        toMoneyFormat,
        async cargarProcesoPublicos() {
            togglePageable(this.procesos);
            const filters = {
                ...this.filtros,
                pagination: true,
                ...this.paginacion,
            };
            const { data, headers } = await this.services.ProcesosPublicos.obtenerProcesoPublicos(filters);
            setPageableResponse(this.procesos, data, headers);
        },
        manejarPaginacionRegistros(paginacion) {
            const { pagina, cantidad_por_pagina } = paginacion;
            this.paginacion = { page: pagina, per_page: cantidad_por_pagina };
        },
        async cargarInstituciones() {
            const filtros = {
                pagination: true,
                per_page: this.paginacionInstituciones.registrosPorPagina,
                page: this.paginacionInstituciones.pagina,
                busqueda: this.busquedas.institucion,
            };

            togglePageable(this.instituciones);
            const copiaInstituciones = this.instituciones.data;
            const { data, headers } = await this.services.ProcesosPublicos.cargarInstituciones(filtros);
            setPageableResponse(this.instituciones, data, headers, { skipOnSuccess: true, persistDataOnError: true });

            if (!isResponseSuccesful(data)) {
                // Hacemos rollback de la pagina en caso de error para volver a cargar la misma al scrollear
                const { pagina } = this.paginacionInstituciones;
                this.paginacionInstituciones.pagina = pagina > 1 ? pagina - 1 : 1;
                return;
            };

            this.instituciones.data = copiaInstituciones.concat(data.data);
        },
        async buscarInstitucion(termino) {
            if (this.busquedas.institucion === termino) return;
            this.busquedas.institucion = termino;
            this.instituciones.data = [];
            this.paginacionInstituciones.pagina = 1;
            this.cargarInstituciones();
        },
        async cargarMasInstituciones() {
            if (this.instituciones.isLoading) return;
            const { page, per_page, total_rows } = this.instituciones.pagination;
            const currentlyLoaded = page * per_page;
            if (!(currentlyLoaded < total_rows)) return;

            this.paginacionInstituciones.pagina = page + 1;
            this.paginacionInstituciones.registrosPorPagina = per_page;
            this.cargarInstituciones();
        },
        limpiarFiltros() {
            this.paginacionInstituciones.pagina = 1;
            this.filtros = {
                id_institucion: null,
                nombre: null,
            }

            this.busquedas = {
                id_institucion: '',
                nombre: '',
            }
            // Limpiar paginaciones
            this.instituciones = createPageable([], 30),
                this.paginacionInstituciones.pagina = 1;

            // Refrescar data
            this.cargarInstituciones();
            this.cargarProcesoPublicos();
        },
        buscarProcesos() {
            this.paginacion.page = 1;
            this.cargarProcesoPublicos();   
        },
        formatDate(date) {
            return moment(date).format("DD-MM-YYYY HH:mm A");
        },
        obtenerNombreBeneficiario(adjudicacion) {
            const beneficiarioFinal = adjudicacion.beneficiario;

            if (beneficiarioFinal) return obtenerNombreCompletoPersona(beneficiarioFinal);

            const accionistaAgenteLegal = adjudicacion.accionistas.find((accionista) => accionista.agente_legal);
            const beneficiario = accionistaAgenteLegal ?? adjudicacion.accionistas[0];

            return beneficiario ? obtenerNombreCompletoPersona(beneficiario.persona) : '-';
        },
        obtenerNacionalidadBeneficiario(adjudicacion) {
            const beneficiarioFinal = adjudicacion.beneficiario;

            if (beneficiarioFinal) return beneficiarioFinal.pais?.gentilicio;

            const accionistaAgenteLegal = adjudicacion.accionistas.find((accionista) => accionista.agente_legal);
            const beneficiario = accionistaAgenteLegal ?? adjudicacion.accionistas[0];
            
            return beneficiario?.gentilicio ??  '-';
        },
    },
    watch: {
        paginacion: {
            handler() {
                this.cargarProcesoPublicos();
            },
            deep: true,
            immediate: true,
        }
    },
    async created() {
        this.cargarInstituciones();
    },
}

</script>
<style scoped>
.external-link {
  font-size: 0.8rem !important;
  text-transform: none;
}


.login-footer {
  position: relative;
  min-height: 80px;
}

.bg-login::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  left: 0;
  top: 0;
}

:deep(.card-wrapper) {
  max-width: 100%;
}

:deep(.login-right-content) {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 0px;
  background-color: #ffffff !important;
}

:deep(.external-link) {
  max-width: 100%;
  width: 100%;
  height: unset !important;
  min-height: unset !important;
  padding: 8px 16px !important;
  justify-content: flex-start;
  text-align: left;
}

:deep(.external-link > span) {
  max-width: 100%;
  word-break: break-word;
  white-space: normal;
  text-align: left !important;
  width: 100%;
}

@media (min-width: 960px) {
  :deep(.login-right-content) {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
    overflow: hidden !important;
    background-color: #313945 !important;
  }

  .option-name,
  .external-link,
  .option-icon,
  .link-icon {
    color: #fff !important;
  }

  .signup-option {
    background-color: #f5f5f5 !important;
  }

  :deep(.signup-option .v-btn__content) {
    color: #313945 !important;
  }

  :deep(.card-wrapper) {
    max-width: 1200px;
  }

  :deep(.login-footer .login-footer-institucion) {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>